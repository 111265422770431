@import "../styles/variables.scss";

.home.view {
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: $mainWidth;

  h2 {
    font-size: 28px;
    padding-bottom: 0;
    padding-top: 32px;
  }

  .body {
    display: flex;
    column-gap: 80px;
    margin-top: $paddingChasm;

    .address {
      font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace; 
    }
  }
}

.card_round:hover {
  cursor: pointer;
}