$colorBackground: #ffffff;
$colorHeader: #000000;
$colorText: #1f1f1f;
$colorSubText: #797979;
$colorHighlight: #306fdc;
$colorHighlight2: #7c9fdb;
$colorTextHighlight: #ffffff;
$colorEdge: #d2d2d2;
$colorDisabled: #d2d2d2;
$colorEdgeHairline: #f9f9f9;
$colorError: #ff0000;
$colorOk: #00aa00;

$mainWidth: 1250px;
$mainMinWidth: 972px;
$mainPadding: 24px;

$paddingHairline: 4px;
$paddingGutter: 8px;
$paddingContent: 18px;
$paddingSmallerGap: 32px;
$paddingGap: 36px;
$paddingChasm: 72px;

$sizeHeader: 32px;
$sizeSubHeader: 32px;
$sizeText: 16px;
$sizeTextSmall: 14px;
$sizeTextSmaller: 12px;
$sizeTextLarge: 18px;
$sizeTextLarger: 20px;
$sizeTextLargest: 24px;
$sizeSmallPrint: 10px;
