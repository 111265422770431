@import "styles/variables.scss";

#root, #api_error {
  height: 100%;
}

#api_error .message {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  width: 300px;
}

#dashboard {
  .menu {
    background-color: #1969FF;

    .item {
      color: #FFFFFF;
    }

    .item:hover {
      cursor: pointer;
    }
    
    a {
      color: #FFFFFF;
    }
  }

  .logo {
    padding: 0.5em;
    padding-right: 5em;
    margin: 0;
    text-align: center;
    img {
      width: 2.5em;
    }
  }
}

#content {
  margin-top: 50px;
}

.main {
  margin-left: auto;
  margin-right: auto;
  max-width: $mainWidth;
  padding: 24px;
}