.address .ui.input>input {
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace; 
}

.selection {
  color: #4183c4;
}

.selection:hover {
  cursor: pointer;
}